import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import initialState from 'src/data/places.json'

export const placesAdapter = createEntityAdapter()

export const slice = createSlice({
  initialState,
  name: 'places',
})

export const {
  selectAll: selectAllPlaces,
  selectById: selectPlaceById,
} = placesAdapter.getSelectors((state) => state.places)

export default slice
