import { random, shuffle } from 'lodash'
import {
  createAction,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import initialState from 'src/data/figures.json'

import { store } from '../store'

export const figuresAdapter = createEntityAdapter()

export const slice = createSlice({
  name: 'figures',
  initialState,
  reducers: {
    shuffleFigures: (state) => {
      state.ids = shuffle(state.ids)
      return state
    },
  },
})

export const selectRandomFigure = () => {
  const figures = store.getState().figures
  const randomId = figures.ids[random(0, figures.ids.length - 1)]
  return figures.entities[randomId]
}

export const shuffleFigures = createAction('figures/shuffleFigures')

export const {
  selectById: selectFigureById,
  selectAll: selectAllFigures,
} = figuresAdapter.getSelectors((state) => state.figures)

export default slice
