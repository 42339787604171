import localforage from 'localforage'
import ReactDOM from 'react-dom'
import { RedwoodProvider, FatalErrorBoundary } from '@redwoodjs/web'
import FatalErrorPage from 'src/pages/FatalErrorPage'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { HEAP_ID } from 'src/consts'
import Routes from 'src/Routes'
import { store, persistor } from 'src/redux/store'
import theme from 'src/theme'

import './index.css'

export const ImagesContext = React.createContext()

const imagesStore = localforage.createInstance({
  name: 'people-party-images',
})

if (window.heap) window.heap.load(HEAP_ID)

ReactDOM.render(
  <FatalErrorBoundary page={FatalErrorPage}>
    <ThemeProvider theme={theme}>
      <ImagesContext.Provider value={{ store: imagesStore }}>
        <ReduxProvider store={store}>
          <RedwoodProvider>
            <CssBaseline />
            <PersistGate loading={null} persistor={persistor}>
              <Routes />
            </PersistGate>
          </RedwoodProvider>
        </ReduxProvider>
      </ImagesContext.Provider>
    </ThemeProvider>
  </FatalErrorBoundary>,
  document.getElementById('redwood-app')
)
