import { get, random, shuffle } from 'lodash'
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { shuffleFigures } from 'src/redux/slices/figures'
import { addPerson, addPeople, updatePeople } from 'src/redux/slices/people'
import { INITIAL_POSITION, MAX_FIGURE_COUNT } from 'src/consts'
import { createId } from 'src/helpers'

export const partiesAdapter = createEntityAdapter()

export const slice = createSlice({
  name: 'parties',
  initialState: partiesAdapter.getInitialState(),
  reducers: {
    addParty: (state, action) => {
      partiesAdapter.addOne(state, {
        ...action.payload,
        removedPeopleIds: [],
        peopleIds: action.payload.peopleIds || [],
        width: 2400,
        height: 1350,
      })
    },
    addPartyPerson: (state, action) => {
      const { id, personId } = action.payload
      const party = state.entities[id]

      partiesAdapter.updateOne(state, {
        id,
        changes: {
          peopleIds: [...party.peopleIds, personId],
        },
      })
    },
    addPartyPeople: (state, action) => {
      const { id, peopleIds } = action.payload
      const party = state.entities[id]

      partiesAdapter.updateOne(state, {
        id,
        changes: {
          peopleIds: [...party.peopleIds, ...peopleIds],
        },
      })
    },
    removePartyPerson: (state, action) => {
      const { partyId, quantity } = action.payload
      const party = state.entities[partyId]
      const removedPeopleIds = party.peopleIds.splice(-quantity, quantity)

      partiesAdapter.updateOne(state, {
        id: partyId,
        changes: {
          peopleIds: party.peopleIds,
          removedPeopleIds: [...party.removedPeopleIds, removedPeopleIds],
        },
      })
    },
    undoRemovePartyPerson: (state, action) => {
      const { partyId } = action.payload
      const party = state.entities[partyId]
      const restoredPersonId = party.removedPeopleIds.pop()

      partiesAdapter.updateOne(state, {
        id: partyId,
        changes: {
          removedPeopleIds: party.removedPeopleIds,
          peopleIds: [...party.peopleIds, restoredPersonId],
        },
      })
    },
    updateParty: (state, action) => {
      partiesAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload.changes,
      })
    },
  },
})

const getRaceDistribution = ({ demographics }) => {
  let results = []
  for (let i = 0; i < MAX_FIGURE_COUNT; i++) {
    results.push(...thinSliceRaceDistribution({ demographics, quantity: i }))
  }

  return results
}

const thinSliceRaceDistribution = ({ demographics, quantity }) => {
  let slice = []

  slice = Object.keys(demographics).reduce((accumulator, race) => {
    const count = Math.round(quantity * (demographics[race] / 100))
    const countNext = Math.round((quantity + 1) * (demographics[race] / 100))
    const countFinal = countNext - count
    return [...accumulator, ...Array(countFinal).fill(race)]
  }, [])

  if (slice.length == 0) {
    // ensure there's always a first. If we get here, the above has
    // failed which means there's no dominant demographic group
    // so instead we will resort to the largest group, even if it's a narrow margin
    let sortable = []
    for (var demo in demographics) {
      sortable.push([demo, demographics[demo]])
    }

    sortable.sort(function (a, b) {
      return b[1] - a[1]
    })

    slice = [sortable[0][0]]
  }

  return slice
}

const getPositionInBounds = ({
  x,
  y,
  width,
  height,
  canvasWidth,
  canvasHeight,
}) => {
  const isInBoundsX = x + width <= canvasWidth && x > 0
  const isInBoundsY = y + height <= canvasHeight && y > 0
  return {
    x: isInBoundsX ? x : Math.max(0, canvasWidth - width),
    y: isInBoundsY ? y : Math.max(0, canvasHeight - height),
  }
}

export const addParty = (action) => async (dispatch, getState) => {
  const { placeId } = action
  const { biopalettes, clothingpalettes, places } = getState()
  const place = places.entities[placeId]

  const initialClothingPaletteId = clothingpalettes.ids[0]
  const clothingPalette = clothingpalettes.entities[initialClothingPaletteId]
  dispatch(shuffleFigures())

  dispatch(
    slice.actions.addParty({
      ...action,
      biopalette: Object.values(biopalettes.entities).reduce(
        (accumulator, biopalette) => ({
          ...accumulator,
          [biopalette.race]: {
            originalId: biopalette.id,
            displayName: biopalette.displayName,
            hair: biopalette.hair,
            skin: biopalette.skin,
          },
        }),
        {}
      ),
      clothingpalette: {
        originalId: initialClothingPaletteId,
        top: clothingPalette.top,
        bottom: clothingPalette.bottom,
        shoes: clothingPalette.shoes,
        accessory1: clothingPalette.accessory1,
        accessory2: clothingPalette.accessory2,
      },
      peopleDemographics: getRaceDistribution({
        demographics: place.demographics,
        partySize: MAX_FIGURE_COUNT,
      }),
    })
  )
}

export const prepareOnePerson = ({
  figures,
  parties,
  people,
  partyId,
  x = undefined,
  y = undefined,
  z = undefined,
  increment = 1,
}) => {
  const party = parties.entities[partyId]

  const partySize = get(party, 'peopleIds', []).length + increment

  const nextFigureId =
    partySize > figures.ids.length
      ? shuffle(figures.ids)[0]
      : figures.ids[partySize - 1]

  const nextFigure = figures.entities[nextFigureId]
  const personId = createId(nextFigure.name + increment, Date.now())

  // TODO more sophisticated math to ensure a better distribution across the canvas
  x = x === undefined ? Math.random() * (party.width - nextFigure.width) : x
  y = y === undefined ? Math.random() * (party.height - nextFigure.height) : y //eslint-disable-line

  const adjustedLocation = getPositionInBounds({
    x,
    y,
    width: nextFigure.width,
    height: nextFigure.height,
    canvasWidth: party.width,
    canvasHeight: party.height,
  })
  z = parseInt(adjustedLocation.y + nextFigure.height)

  return {
    x: adjustedLocation.x,
    y: adjustedLocation.y,
    z: z,
    partyId,
    id: personId,
    figureId: nextFigure.id,
    race: party.peopleDemographics[partySize - 1],
    width: nextFigure.width,
    height: nextFigure.height,
  }
}
export const growParty = ({
  partyId,
  quantity = 1,
  x = undefined,
  y = undefined,
  z = undefined,
} = {}) => async (dispatch, getState) => {
  const { figures, parties, people } = getState()

  let newPeople = []

  for (let i = 1; i <= quantity; i++) {
    newPeople.push(
      prepareOnePerson({
        x,
        y,
        z,
        partyId,
        parties,
        figures,
        people,
        increment: i,
      })
    )
  }

  dispatch(addPeople({ partyId, newPeople }))

  dispatch(
    addPartyPeople({
      peopleIds: newPeople.map((person) => person.id),
      id: partyId,
    })
  )
}

export const shrinkParty = ({ partyId, quantity = 1 }) => async (dispatch) => {
  dispatch(removePartyPerson({ partyId, quantity }))
}

export const updateParty = (action) => async (dispatch, getState) => {
  const { id, changes } = action
  const { parties, people } = getState()

  const party = parties.entities[id]

  if (!party) {
    return console.error(`updateParty: Cannot find party with id \`${id}\``)
  }

  const canvasWidth = changes?.width
  const canvasHeight = changes?.height
  if (!canvasWidth && !canvasHeight) {
    return dispatch(slice.actions.updateParty(action))
  }

  // new canvas width / old canvas width
  const scaleFactor = canvasWidth / party.width

  // reposition figures within new dimensions
  dispatch(
    updatePeople(
      party.peopleIds.map((id) => ({
        id,
        changes: {
          ...people.entities[id],
          ...getPositionInBounds({
            canvasWidth,
            canvasHeight,
            width: people.entities[id].width * scaleFactor,
            height: people.entities[id].height * scaleFactor,
            x: people.entities[id].x,
            y: people.entities[id].y,
          }),
          width: people.entities[id].width * scaleFactor,
          height: people.entities[id].height * scaleFactor,
        },
      }))
    )
  )

  dispatch(slice.actions.updateParty(action))
}

export const updatePartyClothingPalette = (action) => async (
  dispatch,
  getState
) => {
  const { parties } = getState()
  const { colors, part, partyId } = action
  const party = parties.entities[partyId]

  dispatch(
    updateParty({
      id: partyId,
      changes: {
        clothingpalette: {
          ...party.clothingpalette,
          [part]: {
            ...party.clothingpalette[part],
            colors,
          },
        },
      },
    })
  )
}

export const {
  addPartyPerson,
  addPartyPeople,
  removePartyPerson,
  undoRemovePartyPerson,
} = slice.actions

export const {
  selectAll: selectAllParties,
  selectById: selectPartyById,
} = partiesAdapter.getSelectors((state) => state.parties)

export default slice
