import { FIGURE_SCALE_CM_PER_PX } from 'src/consts'

export default function getPersonScale({ figure, person } = {}) {
  if (!figure) {
    console.error('getPersonScale: Must provide `figure` entity object')
    return 1
  }

  if (!person) {
    console.error('getPersonScale: Must provide `person` entity object')
    return 1
  }

  const personOriginalHeight = figure.height / FIGURE_SCALE_CM_PER_PX
  const personCurrentHeight = person.height / FIGURE_SCALE_CM_PER_PX

  return personCurrentHeight / personOriginalHeight
}
