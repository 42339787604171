import chroma from 'chroma-js'
import { max, shuffle, random } from 'lodash'
import { current, createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { BIO_PARTS, CLOTHING_PARTS } from 'src/consts'
import { getPersonScale } from 'src/helpers'

export const peopleAdapter = createEntityAdapter()

const getRandomColor = ({ ranges = [], swatches = [] }) => {
  const colors = []

  if (ranges.length > 1) {
    colors.push(getRandomRangeColor(shuffle(ranges)[0]))
  } else if (ranges.length === 1) {
    colors.push(getRandomRangeColor(ranges[0]))
  }

  if (swatches.length) {
    colors.push(...swatches)
  }
  return shuffle(colors)[0]
}

const getRandomRangeColor = (range) => {
  if (!range) {
    return null
  }

  const { tl, tr, br, bl } = range

  // Construct a sIx DiMeNsIoNaL CoLoR MoDeL and use it as an approximation of the full mesh gradient
  const pairs = [
    [tl, bl],
    [tr, br],
    [bl, br],
    [tl, tr],
    [tl, br],
    [bl, tr],
  ]
  const rangeColors = chroma.scale(shuffle(pairs)[0]).mode('lab').colors(512)
  return shuffle(rangeColors)[0]
}

const getRandomPartColors = ({ parts, palette }) => {
  return parts.reduce((colors, part) => {
    if (!palette[part]) {
      console.error(`getRandomPartColors: ${part} palette is missing`)
      return {}
    }

    const randomColor = getRandomColor({
      ranges: palette[part].ranges,
      swatches: palette[part].swatches,
    })

    if (!randomColor) {
      console.error(`${part} color missing`, palette[part])
    }

    return {
      ...colors,
      [part]: randomColor,
    }
  }, {})
}

const getPersonColors = ({ biopalette, clothingpalette, race }) => {
  if (!biopalette[race]) {
    console.error(`getPersonColors: ${race} biopalette is missing`)
    return {}
  }

  return {
    ...getRandomPartColors({ palette: biopalette[race], parts: BIO_PARTS }),
    ...getRandomPartColors({ palette: clothingpalette, parts: CLOTHING_PARTS }),
  }
}

const getTopZindex = (entities) =>
  Object.keys(entities).length
    ? max(Object.values(entities).map((entity) => entity.z)) + 1
    : 0

export const slice = createSlice({
  name: 'people',
  initialState: peopleAdapter.getInitialState(),
  reducers: {
    addPerson: (state, action) => {
      peopleAdapter.addOne(state, action.payload)
    },
    addPeople: (state, action) => {
      const { newPeople } = action.payload

      peopleAdapter.addMany(state, newPeople)
    },
    bringPersonToFront: (state, action) => {
      const { id } = action.payload

      peopleAdapter.updateOne(state, {
        id,
        changes: {
          z: getTopZindex(current(state).entities),
        },
      })
    },
    updatePeople: (state, action) => {
      peopleAdapter.updateMany(state, action.payload)
    },
    updatePerson: (state, action) => {
      const { id, changes } = action.payload

      peopleAdapter.updateOne(state, {
        id,
        changes,
      })
    },
    updatePersonPosition: (state, action) => {
      const { id, x, y } = action.payload

      peopleAdapter.updateOne(state, {
        id,
        changes: {
          x,
          y,
        },
      })
    },
    updatePersonSize: (state, action) => {
      const { id, width, height, x, y } = action.payload

      peopleAdapter.updateOne(state, {
        id,
        changes: {
          width,
          height,
          x,
          y,
        },
      })
    },

    updatePersonColors: (state, action) => {
      const { id, colors } = action.payload
      peopleAdapter.updateOne(state, {
        id,
        changes: {
          colors,
        },
      })
    },

    horizontalFlipPerson: (state, action) => {
      const { id } = action.payload

      peopleAdapter.updateOne(state, {
        id,
        changes: {
          scale: {
            x: state.entities[id].scale ? state.entities[id].scale.x * -1 : -1,
            y: state.entities[id].scale ? state.entities[id].scale.y : 1,
          },
        },
      })
    },
  },
})

export const addPerson = ({
  figureId,
  height,
  id,
  partyId,
  race,
  width,
  x,
  y,
  z,
}) => async (dispatch, getState) => {
  const { parties, people } = getState()
  const party = parties.entities[partyId]
  if (z === undefined) z = getTopZindex(people.entities)

  dispatch(
    slice.actions.addPerson({
      figureId,
      height,
      id,
      race,
      width,
      x,
      y,
      z,
      colors: getPersonColors({
        race,
        clothingpalette: party.clothingpalette,
        biopalette: party.biopalette,
      }),
    })
  )
}

export const addPeople = ({ partyId, newPeople }) => async (
  dispatch,
  getState
) => {
  const { parties, people } = getState()
  const party = parties.entities[partyId]

  newPeople.forEach((person) => {
    person.colors = getPersonColors({
      race: person.race,
      clothingpalette: party.clothingpalette,
      biopalette: party.biopalette,
    })
  })

  dispatch(slice.actions.addPeople({ id: partyId, newPeople }))
}

export const randomizePersonColors = ({ id, partyId }) => (
  dispatch,
  getState
) => {
  const { parties, people } = getState()

  const party = parties.entities[partyId]
  const colors = {
    skin: people.entities[id].colors.skin,
    hair: people.entities[id].colors.hair,
    ...getRandomPartColors({
      palette: party.clothingpalette,
      parts: CLOTHING_PARTS,
    }),
  }

  dispatch(
    slice.actions.updatePersonColors({
      id,
      colors,
    })
  )
}

export const randomizePartyColors = ({ partyId }) => (dispatch, getState) => {
  const { parties, people } = getState()

  const party = parties.entities[partyId]

  dispatch(
    slice.actions.updatePeople(
      party.peopleIds.map((id) => ({
        id,
        changes: {
          ...people.entities[id],
          colors: {
            skin: people.entities[id].colors.skin,
            hair: people.entities[id].colors.hair,
            ...getRandomPartColors({
              palette: party.clothingpalette,
              parts: CLOTHING_PARTS,
            }),
          },
        },
      }))
    )
  )
}

export const randomizePartyDemographicColors = ({ partyId }) => (
  dispatch,
  getState
) => {
  const { parties, people } = getState()

  const party = parties.entities[partyId]

  dispatch(
    slice.actions.updatePeople(
      party.peopleIds.map((id) => ({
        id,
        changes: {
          ...people.entities[id],
          colors: {
            ...getRandomPartColors({
              palette: party.biopalette[people.entities[id].race],
              parts: BIO_PARTS,
            }),
          },
        },
      }))
    )
  )
}

export const randomizeParty = ({ partyId }) => (dispatch, getState) => {
  const { parties, people, figures } = getState()
  const party = parties.entities[partyId]
  const shuffledFigureIds = shuffle(figures.ids).slice(
    0,
    party.peopleIds.length
  )

  dispatch(
    slice.actions.updatePeople(
      party.peopleIds.map((id, index) => {
        index = index % figures.ids.length
        const figure = figures.entities[shuffledFigureIds[index]]
        const person = people.entities[id]

        const currentScale = getPersonScale({
          person,
          figure: figures.entities[person.figureId],
        })

        return {
          id,
          changes: {
            ...people.entities[id],
            figureId: shuffledFigureIds[index],
            colors: {
              skin: people.entities[id].colors.skin,
              hair: people.entities[id].colors.hair,
              ...getRandomPartColors({
                palette: party.clothingpalette,
                parts: CLOTHING_PARTS,
              }),
            },
            height: figure.height * currentScale,
            width: figure.width * currentScale,
            y: person.y + person.height - (figure.height * currentScale), // eslint-disable-line
            x: person.x,
          },
        }
      })
    )
  )
}

export const updatePersonFigure = ({ id, changes }) => (dispatch, getState) => {
  const { people, figures } = getState()
  const { figureId } = changes

  const currentFigure = figures.entities[people.entities[id].figureId]
  const nextFigure = figures.entities[figureId]
  const person = people.entities[id]

  const currentScale = getPersonScale({
    figure: currentFigure,
    person,
  })

  dispatch(
    slice.actions.updatePerson({
      id,
      changes: {
        figureId,
        height: nextFigure.height * currentScale,
        width: nextFigure.width * currentScale,
        y: person.y + person.height - nextFigure.height * currentScale,
      },
    })
  )
}

export const {
  bringPersonToFront,
  updatePerson,
  updatePersonPosition,
  updatePersonSize,
  updatePersonColors,
  updatePeople,
  horizontalFlipPerson,
} = slice.actions

export const {
  selectById: selectPersonById,
  selectEntities: selectPeopleEntities,
} = peopleAdapter.getSelectors((state) => state.people)

export default slice
