export const ID_NAMESPACE = 'fca6ad36-960b-45f2-8bcc-5262b4f74a85'
export const INITIAL_POSITION = { x: 100, y: 100 }
export const MAX_FIGURE_COUNT = 100
export const FIGURE_SCALE_CM_PER_PX = 3.3333

export const BIO_PARTS = ['hair', 'skin']
export const CLOTHING_PARTS = [
  'top',
  'bottom',
  'accessory1',
  'accessory2',
  'shoes',
]

export const PARTS = [...BIO_PARTS, ...CLOTHING_PARTS]

export const RACES = ['asian', 'black', 'hispanic', 'mixed', 'other', 'white']

export const IS_PROD =
  window.location.hostname === 'peopleparty.app' ||
  window.location.hostname === 'peopleparty.com'

export const HEAP_ID = IS_PROD ? '55932529' : '19528353'
