import { createSlice } from '@reduxjs/toolkit'

import { createRandomId } from 'src/helpers/createId'

export const slice = createSlice({
  initialState: {
    // create unique ID to identify "user" by a Redux store
    id: createRandomId(),
  },
  name: 'user',
})

export default slice
