import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage'

import {
  biopalettes,
  clothingpalettes,
  figures,
  parties,
  people,
  places,
  user,
} from './slices'

// VERSION is injected by Webpack DefinePlugin from package.json
const MAJOR_VERSION = parseInt(VERSION, 10) // eslint-disable-line

export default function getStore({ initialState, isStorybook = false } = {}) {
  const reducer = combineReducers({
    biopalettes: biopalettes.reducer,
    clothingpalettes: clothingpalettes.reducer,
    figures: figures.reducer,
    parties: parties.reducer,
    people: people.reducer,
    places: places.reducer,
    user: user.reducer,
  })

  const persistConfig = {
    key: 'people-party',
    storage: localforage,
    whitelist: ['clothingpalettes', 'biopalettes', 'parties', 'people', 'user'], // TODO: https://github.com/rt2zz/redux-persist/pull/1213
    version: MAJOR_VERSION,
  }

  return configureStore({
    reducer: isStorybook ? reducer : persistReducer(persistConfig, reducer),
    middleware: getDefaultMiddleware({
      serializableCheck: false, // conflicts with redux-persist
    }),
    preloadedState: initialState,
  })
}

export const store = getStore()
export const persistor = persistStore(store)
