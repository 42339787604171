import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import initialState from 'src/data/clothingpalettes.json'

export const clothingpalettesAdapter = createEntityAdapter()

export const slice = createSlice({
  name: 'clothingpalettes',
  initialState,
})

export const {
  selectAll: selectAllClothingPalettes,
  selectById: selectClothingPaletteById,
} = clothingpalettesAdapter.getSelectors((state) => state.clothingpalettes)

export default slice
