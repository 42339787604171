import { clone } from 'lodash'

const yaml = require('js-yaml')

export default function serializePalette(palette) {
  let clipboardPalette = clone(palette)
  delete clipboardPalette.edited
  delete clipboardPalette.originalId
  delete clipboardPalette.something
  var tempInput = document.createElement('textarea')
  tempInput.value = yaml.safeDump(clipboardPalette)
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
  alert('Palette copied to clipboard!')
}
