import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import initialState from 'src/data/biopalettes.json'

export const biopalettesAdapter = createEntityAdapter()

export const slice = createSlice({
  name: 'biopalettes',
  initialState,
})

export const {
  selectById: selectPaletteById,
} = biopalettesAdapter.getSelectors((state) => state.palettes)

export default slice
