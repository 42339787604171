import { v5 as uuid, v4 as uuidv4 } from 'uuid'

import { ID_NAMESPACE } from 'src/consts'

export default function createId(...nameParts) {
  if (!nameParts) {
    console.error(
      new Error(
        'createId: Must specify one or more `name` arguments for uniqueness'
      )
    )
    return null
  }

  return uuid(nameParts.join('-'), ID_NAMESPACE)
}

export function createRandomId() {
  return uuidv4()
}
