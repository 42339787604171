import { createMuiTheme } from '@material-ui/core/styles'

const BLACK = '#000'
const GREEN = '#219653'
const PURPLE = '#6200EE'
const LIGHT_PURPLE = '#DBD3E6'
const WHITE = '#fff'

const grayscale = {
  darkest: 'hsla(0, 0%, 0%, 0.13)',
  darker: 'hsla(0, 0%, 20%, 1)',
  dark: 'hsla(0, 0%, 0%, 0.40)',
  main: 'hsla(0, 0%, 0%, 0.60)',
  light: 'hsla(0, 0%, 0%, 0.67)',
  lighter: 'hsla(0, 0%, 0%, 0.87)',
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: GREEN,
    },
    secondary: {
      main: PURPLE,
      light: LIGHT_PURPLE,
    },
    background: {
      default: GREEN,
      paper: WHITE,
    },
    canvas: 'hsl(0, 0%, 67%)',
    divider: 'hsl(0, 0%, 77%)',
    lightDivider: 'hsla(0, 0%, 13%, 0.08)',
    placeholder: GREEN,

    // named colors

    black: BLACK,
    gray: grayscale,
    white: WHITE,
  },
  typography: {
    h1: {
      color: grayscale.lighter,
      fontSize: 20,
      lineHeight: 1.2,
      fontWeight: 400,
      letterSpacing: 0.15,
    },
    h2: {
      marginBottom: 16,
      color: grayscale.lighter,
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 700,
      letterSpacing: 0.15,
    },
    h3: {
      color: grayscale.main,
      fontSize: 20,
      fontWeight: 700,
    },
    h4: {
      color: grayscale.main,
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 700,
    },
    body1: {
      color: grayscale.main,
      fontSize: 14,
      lineHeight: 1.2,
      fontWeight: 400,
    },
  },
  overrides: {
    MuiAutocomplete: {
      groupLabel: {
        color: BLACK,
      },
      groupUl: {
        '& $option': {
          paddingLeft: 16,
        },
      },
    },
    MuiButton: {
      iconSizeSmall: {
        marginRight: 6,
        '& > *:first-child': {
          fontSize: '1.5rem',
        },
      },
      outlinedSizeSmall: {
        paddingTop: 0,
        paddingBottom: 0,
        color: grayscale.main,
        fontSize: '0.875rem',
        letterSpacing: 1.25,
      },
    },
    MuiMenuItem: {
      root: {
        color: grayscale.darker,
      },
    },
    MuiListItemIcon: {
      root: {
        color: grayscale.darker,
      },
    },
    MuiListItemText: {
      primary: {
        color: grayscale.darker,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
})

theme.sizing = theme.spacing

export default theme
