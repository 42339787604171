// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Redirect, Router, Route } from '@redwoodjs/router'

import { store } from 'src/redux/store'

const Routes = () => {
  const userId = store.getState().user?.id

  if (window.heap) {
    if (userId) window.heap.identify(userId)

    // VERSION is injected by Webpack DefinePlugin from package.json
    if (VERSION) window.heap.addEventProperties({ Version: VERSION }) // eslint-disable-line
  }

  return (
    <Router>
      <Route name="newParty" path="/" page={PartyLocationPage} />
      <Route name="Reset" path="/reset" page={Reset} />
      <Route name="party" path="/party/{partyId}" page={PartyPage} />
      <Route
        name="partyLocation"
        path="/party/{partyId}/location"
        page={PartyLocationPage}
      />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
